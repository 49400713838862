import { Link as ScrollLink } from 'react-scroll'
import { Box, SpanStyled } from '../Styled/Elements'
import { FooterContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { ButtonWithIcon, PrimaryHeading, TextWithIcons } from '../Styled/CustomElements'
import { Email, Facebook, FooterImg, Instagram, Location, Logo, NextArrow, Phone, Twitter } from '../Styled/AllImages'

const Footer = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const navigate = useNavigate();
    const date_year = new Date();
    const WhatsApp = () => {
        const phoneNumber = '0581506065';
        const message = 'Hello, FOWL';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    // const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
          };

        
        window.addEventListener('scroll', handleResize);
        return () => {
            window.removeEventListener('scroll', handleResize);
        };
    }, []);
    
    return (
        <>
        <FooterContainer className="container-fluid">
            <Container>
                <Row>
                    <Col md={12} lg={4} className='footerLeftCol'>
                        <Box className='logoFooter' onClick={() => navigate('/home')}>
                            <Logo />
                        </Box>
                        <SpanStyled size='15px' className='footerText'>
                            {t("tab1_Text1")}
                        </SpanStyled>
                        <Box className='workBtn'>
                            <ButtonWithIcon 
                                portClass='btnPryMedium'
                                label={t('Work_with_us')}
                                icon={NextArrow} 
                                onClick={WhatsApp}
                            />
                        </Box>
                        
                    </Col>
                    <Col md={12} lg={8} >
                        <Box className='mainHeading mb-4'>
                            <PrimaryHeading HlabelS={t('Lets_Build')} customClass="prFooterHeading" size="30px" />
                        </Box>
                        <Row className='navAddDet'>
                            <Col xs={6} lg={3} xl={2} className='navCol navColF'>
                                <Box className='nav-bar-list-footer'>
                                    {NavDataConfig.slice(0, 3).map((item, key) => (
                                        <Box key={key}>
                                            {screenWidth <= 991 && (
                                                <ScrollLink to={item.to} spy={true} smooth={true} offset={-50} duration={400} activeClass="active" >{t(item.label)}</ScrollLink>
                                            )}
                                            {screenWidth > 991 && (
                                                <NavLink to={`/${item.to}`} >{t(item.label)}</NavLink>
                                            )}
                                        </Box>
                                    ))}
                                    
                                </Box>
                            </Col>
                            <Col xs={6} lg={3} xl={2} className='navCol navColS'>
                                <Box className='nav-bar-list-footer'>
                                    {NavDataConfig.slice(3, 6).map((item, key) => (
                                        <Box key={key}>
                                            {screenWidth <= 991 && (
                                                <ScrollLink to={item.to} spy={true} smooth={true} offset={-50} duration={400} activeClass="active" >{t(item.label)}</ScrollLink>
                                            )}
                                            {screenWidth > 991 && (
                                                <NavLink to={`/${item.to}`} >{t(item.label)}</NavLink>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Col>
                            <Col lg={6} xl={8} className='colAddFooter'>
                                <TextWithIcons customClass="mb-3" icon={Location} label={t('contactAddress')} />
                                <TextWithIcons customClass="mb-3" icon={Phone} label='0581506065' />
                                <TextWithIcons customClass="mb-4" icon={Email} label='info@fowl.sa' />
                                <Box className='processSocial'>
                                    <Link to="#!"><Facebook /></Link>
                                    <Link to="#!"><Twitter /></Link>
                                    <Link to="#!"><Instagram /></Link>
                                </Box>
                            </Col>
                        </Row>
                        
                    </Col>
                    <Box className='copyRight'>
                        <SpanStyled size='15px' className='copyRightText'>
                            {t('All_Right_Reserved')} &copy; {date_year.getFullYear()}
                        </SpanStyled>
                        <div className="cRLink" onClick={() => navigate('/home')}>{t('Fowl')}</div>
                    </Box>
                </Row>
            </Container>
            <img className='footer-img' src={FooterImg} alt="Footer" />
        </FooterContainer>

        
        </>
    )
}


export default Footer
