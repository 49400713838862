import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../../Styled/Elements'
import { PrimaryHeading } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from './Styled'
import TestimonialsSlider from './TestimonialsSlider'

const Testimonials = () => {
    const { t } = useTranslation();
    

    return (
        <MainWrapper className='section-common mainWrapper' id='testimonials'>
            <Container className='container-fluid'>
                <Row className='main-wrap'>
                    <Box className='mainHeading mb-4'>
                        <PrimaryHeading HlabelF={t("What_our")} HlabelS={t("Clients_have_to_say")} customClass="text-center" />
                    </Box>
                    <SpanStyled size='15px' className='mb-4 text-center mainMainTxt'>
                        {t("Tes_Txt")}
                    </SpanStyled>
                    <Col md={12}>
                        <TestimonialsSlider />
                    </Col>
                    
                </Row>
            </Container>
        </MainWrapper>
    )
}

export default Testimonials
