import React from 'react'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, SpanStyled } from '../../Styled/Elements'
import { Cross } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import BeepVisitListing from './BeepVisitList'
import { portfolioModal } from '../../data/Data'




const CustomModal = ({ show, onHide, modalId }) => {

    const currentItem = portfolioModal.find(item => item.id == modalId)
    
    const { t } = useTranslation();
    return (
        <>
        <Modal 
            show={show && modalId !== null} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal"
            // id={0}
        >
            
            <Modal.Body className='portfolioBody'>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                {modalId && (
                    <>
                        <section>
                            <Container className='containerPr container-fluid'>
                                <Row className='leftRightColWrap portfolio-wrap'>
                                    <Box className='leftColCntnt leftColPortfolio'>

                                        <Row className="leftColCntntRow">
                                            <Col lg={12}>
                                                <Box className='projectLogoName'>
                                                    <Box className='projectLogo'>
                                                        {currentItem.logoImg}
                                                    </Box>
                                                    <Box className='projectModTitle'>
                                                        {t(currentItem.projectName)}
                                                    </Box>
                                                </Box>
                                                
                                                
                                            </Col>
                                            <Col lg={12} className='sthDesc'>
                                                <Box className="smallTitleHeading">
                                                    {t('Scope')}
                                                </Box>
                                                <SpanStyled size='15px' className='projectDesc'>
                                                    {t(currentItem.projectDesc)}
                                                </SpanStyled>
                                                
                                            </Col>
                                            {currentItem.projectMoreDetail}
                                            {currentItem.projectList}
                                            
                                            <BeepVisitListing visitList={currentItem.projectVisit} />
                                            
                                            
                                        </Row>
                                        
                                    </Box>
                                </Row>

                            </Container>
                        </section>
                        <Box className='rightColImg rightColImgBeep'>
                            {currentItem.mainImg}
                        </Box>
                        
                    </>
                )}
            </Modal.Body>
            
        </Modal>
        
        </>
    );
};

export default CustomModal;
