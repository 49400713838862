import styled from 'styled-components'

export const MainWrapper = styled.section`
    
`
export const TesimonialSliderWrapper = styled.div`
    .rhap_container{
        background: var(--bgGradient);
        padding: 14px 15px;
        border-radius: 28px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -26px;
        margin: auto;
        display: none;
    }
    .rhap_main-controls-button{
        color: #fff;
        width: 26px;
        height: 26px;
        font-size: 26px;
        margin: 0;
        // svg{
        //     width: 26px;
        // }
    }
    .rhap_time{
        color: var(--white);
        font-size: 12px;
        padding-top: 1px;
        padding: 0 3px 0 3px;
    }
    .rhap_current-time{
        padding-left: 6px;
        width: 41px;
        padding-right: 0;
    }
    .rhap_total-time{
        padding-right: 6px;
        padding-right: 0;
        width: 39px;
    }
    .slashAudio{
        color: var(--white);
        font-size: 12px;
        
        padding-top: 1px;
    }
    .rhap_progress-indicator {
        width: 12px;
        height: 12px;
        margin-left: -4px;
        top: -4px;
        background: var(--white);
    }
    .rhap_progress-container {
        height: 10px;
        margin: 0 calc(5px + 1%);
        // margin-left: -5px;
        // top: -3px;
    }
    .rhap_progress-filled {
        height: 100%;
        position: absolute;
        z-index: 2;
        background-color: var(--white);
        border-radius: 2px;
    }
    .rhap_volume-indicator {
        background-color: var(--white);
        margin-left: -8px;
        opacity: 1;
    }
    .rhap_volume-button {
        font-size: 24px;
        color: var(--white);
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .rhap_volume-container {
        flex: 0 1 70px;
    }
    .rhap_volume-bar-area {
        padding-right: 3px;
    }
`

