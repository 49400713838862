import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../../Styled/Elements'
import { PrimaryHeading } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from './Styled'
import PortfolioSlider from './PortfolioSlider'
import { portfolioItems } from '../../data/Data'
import ProductsTab from './ProductsTab'

const Portfolio = () => {
    const { t } = useTranslation();
    
    const uniqueData = [
        "All",
        ...new Set(
            portfolioItems.map((data) => {
                return data.category;
            })
        ),
        
    ];
    const [menuItem, setMenuItem] = useState(portfolioItems);
    const [menuList, setMenuList] = useState(uniqueData);

    const fillter = (category) => {
        if (category === "All") {
            setMenuItem(portfolioItems);
            return;
        }
        const updateMenu = portfolioItems.filter((data) => {
            return data.category === category;
        });
        setMenuItem(updateMenu);

    };

    return (
        <MainWrapper className='section-common mainWrapper' id='portfolio'>
            <Container className='container-fluid'>
                <Row className='main-wrap'>
                    <Box className='mainHeading'>
                        <PrimaryHeading HlabelF={t("Our")} HlabelS={t("Projects")} customClass="text-center" />
                    </Box>
                    <SpanStyled size='15px' className='mb-4 text-center mainMainTxt'>
                        {t('Projects_Text')}
                    </SpanStyled>
                    <Col md={12}>
                        <ProductsTab fillter={fillter} menuList={menuList} />
                        {/* <ProductCard menuItem={menuItem} /> */}
                        <PortfolioSlider menuItem={menuItem} />
                    </Col>
                    
                </Row>
            </Container>
        </MainWrapper>
    )
}

export default Portfolio
