import React from 'react'
import Slider from 'react-slick'
import { Box } from '../../Styled/Elements'
import { SliderArrowLeft, SliderArrowRight } from '../../Styled/AllImages'
import { clientsItems } from '../../data/Data'
import { Link } from 'react-router-dom'



const ClientsSlider = () => {
    
    
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 1500,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft />,
        nextArrow: <SliderArrowRight />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1099,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <Box className='clientsColWrap'>

            <Slider {...settings}>
                {clientsItems.map((item, index) => (
                    <Box className='clientsCol' key={index}>
                        <Box className='clientsIcon'>
                            <Link to="#!">{item.img}</Link>
                        </Box>
                    </Box>
                ))}
            </Slider>

        </Box>
    )
}

export default ClientsSlider
