import React, { useState, useEffect } from 'react'
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Services from '../components/sections/services/Services'
import Portfolio from '../components/sections/portfolio/Portfolio'
import Team from '../components/sections/team/Team'
import Testimonials from '../components/sections/testimonials/Testimonials'
import Clients from '../components/sections/clients/Clients'
import Contact from '../components/sections/contact/Contact'
import Footer from '../components/footer/Footer'
import Faqs from '../components/sections/faqs/Faqs'
import ErrorPage from '../components/sections/Page404/ErrorPage'
import Header from '../components/header/Header'
import GlobalElements from "../components/sections/elements/GlobalElements"


const Index = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
    return (
        <>
            
            {screenWidth > 991 ? (
                <>
            {/* <Header />
            <GlobalElements /> */}
            <Banner />
            </>
            // <About />
            ) : (
                <>
                <Header />
            <Banner />
            <About />
            <Services />
            <Portfolio />
            {/* <Team /> */}
            <Testimonials />
            <Clients />
            <Faqs />
            {/* <Contact /> */}
            <Footer />
            {/* <ErrorPage /> */}
            </>
                )}
        </>
    )
}

export default Index