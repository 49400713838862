import React, { useState } from 'react'
import { ContactFormWrapper } from './Styled'
import { Controller, useForm } from "react-hook-form"
import { Box, SpanStyled } from '../../Styled/Elements'
import { ButtonWithIcon, CustomCheckbox } from '../../Styled/CustomElements'
import { Company, Email, FName, Message, NextArrow, Phone } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { agendaOption } from '../../data/Data'
const ContactForm = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);


    const { register, control, handleSubmit, setValue, getValues, trigger, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            company: '',
            email: '',
            phone: '',
            looking_for: [],
            message: '',
            language: '',
        }
    })

    const handleCheckbox = (e) => {
        const { name, value, checked } = e.target;

        const prevVal = getValues();

        let updatedBoxes = prevVal[name];

        if (prevVal.length === 0 || !prevVal[name].includes(value)) {
            updatedBoxes.push(value);
        } else {
            updatedBoxes = updatedBoxes.filter(item => item !== value);
        }
        console.log(prevVal[name]);
        setValue(name, updatedBoxes);

        trigger(name);
    }

    const getApi = async (data) => {
        try {
            setIsLoading(true);

            
            

            const response = await axios.post("https://fowl.sa/api/public/api/v1/quote-info", data);
            if (response.status === 200) {
                reset({
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    looking_for: [],
                    message: '',
                    language: '',
                });
                // toast.success("Your submission was successful.", { autoClose: 3000 });
                toast.success(t('submission'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // className: 'message'
                })
                setIsLoading(false);
            } else {
                toast.error(t('submission_fail'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            // alert("Submission has failed.");
            toast.error(t('submission_fail'), {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsLoading(false);
        }
    }
    
    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        // Allow only positive numbers
        const input = e.target.value;
        if (!isNaN(input) && input >= 0) {
            setPhone(input);
        }
    };


    const onSubmit = async (formData) => {
        // console.log(formData);
        // if (validation()) {
        getApi(formData)
        // }
    };

    return (
        <>

            <ContactFormWrapper >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className="field-box-wh">
                        <Box className='field-box'>
                            <img src={FName} alt='Icon' />
                            {/* <LabelStyled color='var(--themeColor)' >{t('email')}</LabelStyled> */}
                            <input className='input-field' placeholder={t('FullName')}  {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                            {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)}
                        </Box>
                        <Box className='field-box'>
                            <img src={Company} alt='Icon' />
                            <input className='input-field' placeholder={t('Company')} />
                            {/* {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)} */}
                        </Box>
                    </Box>
                    <Box className="field-box-wh">
                        <Box className='field-box'>
                            <img src={Email} alt='Icon' />
                            {/* <LabelStyled color='var(--themeColor)' >{t('email')}</LabelStyled> */}
                            <input className='input-field' placeholder={t('emailPlaceholder')} type='email'{...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                            {errors.email && <p className='error-message' role="alert">{t('email_error')}</p>}
                        </Box>
                        <Box className='field-box'>
                            <img src={Phone} alt='Icon' />

                            {/* <LabelStyled color='var(--themeColor)' >{t('contactPhone')}</LabelStyled> */}
                            <input className='input-field' placeholder={t('phonePlaceholder')} type='text' value={phone} {...register("phone", { required: true })} aria-invalid={errors.phone ? "true" : "false"} onChange={handlePhoneChange} />
                            {errors.phone && <p className='error-message' role="alert">{t('phone_error')}</p>}
                        </Box>
                    </Box>
                    <SpanStyled size='18px' family="var(--semiBold)" className='mb-3 contactTxt'>
                        {t('What_are_you')}
                    </SpanStyled>
                    <ul className="d-flex list-unstyled d-flex flex-wrap optionListWrapper">
                        {/* {formErrors.looking_for && <p className='error-message' role="alert">{formErrors.looking_for}</p>} */}
                        {agendaOption?.map((item) => (
                            <li className="custom-radio d-flex align-items-center">
                                <Controller
                                    name='looking_for'
                                    control={control}
                                    rules={{ required: true ? t("Select_atleast_one") : "" }}
                                    // onChange={handleCheckbox}
                                    render={({ field }) => (
                                        <CustomCheckbox
                                            name='looking_for'
                                            label={t(item?.label)}
                                            value={item?.value}
                                            onChange={(e) => handleCheckbox(e)}
                                            labelClass=""
                                        />
                                    )}
                                />
                            </li>
                        ))}
                        {errors['looking_for'] && <p className='error-message' role="alert">{errors['looking_for'].message}</p>}

                    </ul>
                    <Box className='field-box'>
                        {/* <LabelStyled color='var(--themeColor)' >{t('yourMessage')}</LabelStyled> */}
                        <img src={Message} alt='Icon' />
                        <textarea className='input-field' placeholder={t('messagePlaceholder2')} cols="30" rows="6" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} />
                        {errors.message && <p className='error-message' role="alert">{t('message_error')}</p>}
                    </Box>
                    <Box className='d-flex justify-content-end mt-3'>
                        {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                        <ButtonWithIcon
                            label={t('Send')}
                            icon={NextArrow}
                            portClass='btnMed'
                            padding='6px 20px'
                            radius='18px'
                        />
                    </Box>
                </form>
                <ToastContainer />
            </ContactFormWrapper>

        </>
    )
}

export default ContactForm