import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, Span, TextStyled } from '../../Styled/Elements'
import { ButtonWithIcon, PrimaryHeading} from '../../Styled/CustomElements'
import { ArrowDown, ArrowUp, Search } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { FaqMainWrapper } from './Styled'
import { allFaqs } from '../../data/Data'

const Faqs = () => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const toggleItem = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };
    return (
        <FaqMainWrapper className='section-common contactWrapper' id='faqs'>
            <Container className='container-fluid'>
                <Row className='leftRightColWrapContact faq-wrap'>
                    <Col sm={12} lg={10} className='contentCol leftColContact'>
                        <Box className='faqsHeading'>
                            <PrimaryHeading HlabelF={t("Have")} HlabelS={t("AnyQuestions")} />
                            <Box className="field-box-wh">
                                <Box className='field-box'>
                                    <input className='input-field' placeholder={t('Search')} type='text' />
                                    <button  style={{ backgroundImage: `url(${Search})` }}
                                        // label={t('Send')} 
                                        // icon={Search} 
                                        
                                        className='btnSearch'
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box className='item-wrapper'>
                            {allFaqs.map((list, index) => (
                                <Box key={index} className={`item-container  ${activeItem === index ? 'active-item-box' : ''}`}>
                                    <Box className='item-box' onClick={() => toggleItem(index)}>
                                        <Span className='d-flex gap-2 align-items-center item-box-heading'>
                                            {list.icon}
                                            {t(list.heading)}
                                        </Span>
                                        {activeItem === index ? <ArrowUp /> : <ArrowDown />}
                                    </Box>
                                    {activeItem === index && (
                                        <Box>
                                            <Box>
                                                <TextStyled size='14px' color='var(--darkBlue)'>{t(list.text)}</TextStyled>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                        
                    </Col>
                    
                </Row>
            </Container>
        </FaqMainWrapper>
    )
}

export default Faqs
