import React from 'react'
import { Col } from 'react-bootstrap'
import { Box } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const BeepVisitListing = ({ visitList }) => {
    const { t } = useTranslation();

    return (
        <>
            {visitList && visitList.length > 0 ? <Col lg={12} className='sthDesc mb-0'>
                <Box className="smallTitleHeading">
                    {t('Visit_Now')}
                </Box>
                <Box className='portfolioVisitListWrapper'>
                    {visitList.map((item, index) => (
                        <Link className="visitLink" to={item.beepLink} key={index} target="_blank">
                            {item.beepVisit}
                        </Link>
                    ))}
                </Box>
            </Col> : null}
        </>

    )
}

export default BeepVisitListing
