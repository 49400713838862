import React, { useState } from 'react'
import Slider from 'react-slick'
import { Box } from '../../Styled/Elements'
import { SliderArrowLeft, SliderArrowRight } from '../../Styled/AllImages'
import CustomModal from './ModalProcess'


const PortfolioSlider = ({ menuItem }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 4,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft />,
        nextArrow: <SliderArrowRight />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1099,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    const [showModals, setShowModals] = useState(null); // State to track which modal is open
    const [showModal, setShowModal] = useState(false); // State to track which modal is open

    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const openModals = (modalId) => {
        console.log(modalId);
        setShowModals(modalId); // Set the state to the modal identifier
            setShowModal(true)
    };

    
    return (
        <Box className='portfolioColWrap'>

            <Slider {...settings}>
                {
                    menuItem.map((data, key) => {
                        const { img, render } = data;

                       return(
                        <Box className='portfolioCol' key={key}>
                            <Box className='portfolioIcon'>
                                {img}
                            </Box>

                            <Box className='portfolioDetailBtn'>
                                {render(openModals)} 
                            </Box>
                        </Box>
                       )
                    })
                    
                }
            </Slider>
                

            <CustomModal
                // key={index}
                show={showModal} // Show modal based on its id
                onHide={() => {
                    setShowModal(false)
                    setShowModals(null)
                }} // Close modal function
                modalId={showModals} // Pass the modal identifier
            />
        </Box>
    )
}

export default PortfolioSlider
