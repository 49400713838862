import React from 'react'
import { Col } from 'react-bootstrap'
import { SpanStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { DaimounList } from '../../data/Data'

const DaimounListing = () => {
    const { t } = useTranslation();
    
    return (
        <Col lg={12} className='sthDesc'>
            <ul className='portfolioListWrapper'>
                {DaimounList.map((item, index) => {
                    console.log(item)
                    return (
                    <li className="unList" key={index}>
                        <SpanStyled color='var(--darkBlue)' size='16px' >
                            {t(item.ListTxt)}
                        </SpanStyled>
                    </li>
                )})}
            </ul>
        </Col>
        
    )
}

export default DaimounListing
