import styled from 'styled-components'

export const ContactMainWrapper = styled.section`
    .rightColContentContact{
        padding-left: 30px;
    }
    .contactMap{
        width: 100%;
        
        iframe{
            width: 100%;
            border-radius: 15px;
            overflow: hidden;
        }
    }
`

export const ContactFormWrapper = styled.div`
    width:100%;
    

`