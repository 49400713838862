import React from 'react'
import Slider from 'react-slick'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { SliderArrowLeft, SliderArrowRight, TesAudio } from '../../Styled/AllImages'
import { testimonialsItems } from '../../data/Data'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import { TesimonialSliderWrapper } from './Styled'


const TestimonialsSlider = () => {
    const { t } = useTranslation();
    
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 1500,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft />,
        nextArrow: <SliderArrowRight />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <TesimonialSliderWrapper className='testimonialsColWrap'>
            
            <Slider {...settings}>
                {testimonialsItems.map((item, index) => (
                    <Box className='testimonialsCol' key={index}>
                        
                        <Box className='imgQuote'>{item.imgQuote}</Box>
                        <Box className='testimonialsIcon'>
                            {item.img}
                        </Box>
                        <Box className='testimonialsHeading'>{t(item.tesHeading)}</Box>
                        <Box className='testimonialsDesig'>{t(item.tesDesig)}</Box>
                        <SpanStyled className='testimonialsText' size="15px">
                            {t(item.tesTxt)}
                        </SpanStyled>
                        <AudioPlayer
                            // autoPlay
                            src={TesAudio}
                            onPlay={e => console.log("onPlay")}
                            // other props here
                            // layout="stacked-reverse"
                            // layout="horizontal-reverse"
                            customProgressBarSection={
                                [
                                    RHAP_UI.MAIN_CONTROLS,
                                    RHAP_UI.CURRENT_TIME,
                                    <div className='slashAudio'>/</div>,
                                    RHAP_UI.DURATION,
                                    RHAP_UI.PROGRESS_BAR,
                                    RHAP_UI.VOLUME,
                                
                                
                                
                                ]
                            }
                            //   customIcons={{
                            //     play: false
                            //   }}
                            customAdditionalControls={[]}
                            //   mainControls={[]}
                            customPlayControls={[]}
                            showJumpControls={false}
                            customVolumeControls={[]}
                            //   customIcons={[]}
                            showFilledProgress={true}
                            
                            //   showFilledVolume={false}
                            //   mainControls={false}
                            
                            style={{
                                width: '300px'
                            }}
                            
                            //   defaultCurrentTime="Loading" defaultDuration="Loading"
                        />
                        
                    </Box>
                    
                ))}
            </Slider>

            
        </TesimonialSliderWrapper>
    )
}

export default TestimonialsSlider
