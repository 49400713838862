import styled from 'styled-components'

export const MainWrapper = styled.section`
    
`


export const ProductTabWapper = styled.div`

.tabWrapperProduct{
    display: flex;
    // flex-direction: row-reverse;
    width: 100%;
    border-top: 1px solid var(--text);
    border-bottom: 1px solid var(--text);
    // justify-content: space-evenly;
    gap: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    // overflow-x: auto;
    justify-content: center;
}
.TabButtonWrapper{
    padding: 8px 0px;
    margin:15px 0;
    position: relative;
    cursor: pointer;
    // margin: 0 10px 0 10px;
    &::after{
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 4px;
        width: 100%;
        background: transparent;
        border-radius: 2px;
    }
    span{
        display: flex;
        align-items: center;
        color: var(--darkBlue);
        font-size: 18px;
        font-family: var(--regular);
        text-align: center;
        white-space: nowrap;
        transition: all .4s ease-in-out;
        
    }
    &.active {
        // background: var(--darkBlue);
        
        // border-radius:20px;
        // box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        // -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        // -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        &::after{
            background: var(--bgGradient);
        }
    }
    &.active span {
        background:  var(--bgGradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-family: var(--semiBold);
    }
    &:hover{
        // background: var(--gradientDark);
        // border-radius:20px;
        // box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        // -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        // -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        &::after{
            background: var(--bgGradient);
        }
    }
    &:hover span {
        background:  var(--bgGradient);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }
}
`