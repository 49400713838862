
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Box } from './components/Styled/Elements';
import { privateRoute, privateRouteScroll } from './route/PrivateRoute';
import { Route, Routes, useLocation, Switch, Navigate } from 'react-router-dom';
import Header from './components/header/Header';
import GlobalElements from "./components/sections/elements/GlobalElements"
// import { Oval } from 'react-loader-spinner';
import useArrowNavigation from './components/hooks/useArrowNavigation';
import ErrorPage from './components/sections/Page404/ErrorPage';


function App() {
	const location = useLocation();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	// const [loader, setLoader] = useState(false)
	const [pathTransitions, setPathTransitions] = useState({});
	// const [redirected, setRedirected] = useState(false);

	// useLayoutEffect(() => {
	// 	setLoader(true)
	// }, [])

	// useEffect(() => {
	// 	setLoader(false)
	// }, [])
	
	
	useArrowNavigation();
		
	

	useEffect(() => {
		document.body.classList.add('home');
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	/* useEffect(() => {
		// Set default class to "home" on load
		document.body.classList.add('home');
		// const defPath = location.pathname;
		// document.body.classList.add(defPath);
	
		// Cleanup function to remove event listeners
		return () => {
		  document.body.classList.remove('home');
		};
	  }, []); */
	
	  useEffect(() => {
		// Update path transitions when location changes
		if(screenWidth > 991){
			const currentPath = location.pathname;
		const prevPath = Object.keys(pathTransitions).length > 0 ? Object.keys(pathTransitions)[0] : null;
		//////////For Fade
		//setPrevPaths(location.pathname);
		
		////////////
		if (prevPath && currentPath !== prevPath) {
		  const newPathTransitions = { ...pathTransitions };
		  delete newPathTransitions[prevPath];
		  newPathTransitions[currentPath] = `${prevPath.substring(1)}-to-${currentPath.substring(1)}`;
		  setPathTransitions(newPathTransitions);
		} else if (!prevPath) {
		  setPathTransitions({ [currentPath]: null });
		}
		// document.body.classList.add(currentPath);
		// Add transition classes to body
		const transitionClass = pathTransitions[currentPath];
		if (transitionClass) {
		  document.body.classList.add(transitionClass);
		}
	
		// Remove transition classes when component unmounts
		return () => {
		  if (transitionClass) {
			document.body.classList.remove(transitionClass);
		  }
		};
		}
	  }, [location, pathTransitions, screenWidth]);

	  
	return (
		<>
			{/* {loader ? (
					<Oval
					height={80}
					width={80}
					color="#9E50ED"
					wrapperStyle={{}}
					wrapperClass="site_loader"
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#A168E6"
					strokeWidth={2}
					strokeWidthSecondary={2}
				  />
				) :  */}
			<Box className='main-artical'>
				<Header />
				<GlobalElements />
				
				{screenWidth > 991 ? (

					<Routes>
						
						{privateRoute.map((list, key) => (
							<Route to={key} {...list} />
						))}
						<Route path="*" element={<ErrorPage />} />
						{/* Set the default route to "/home" */}
						<Route path="/" element={<Navigate to="/home" />} />
					</Routes>

				) : (
					<Routes>
						
						{privateRouteScroll.map((list, key) => (
							<Route to={key} {...list} />
						))}
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				)
				}
			</Box>
			{/* } */}
		</>
	);
}

export default App;
