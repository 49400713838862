import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useScreenWidth from './useScreenWidth'; // Import the custom hook

const useArrowNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth(); // Get the current screen width
  const routes = ['/home', '/about', '/services', '/portfolio', '/testimonials', '/clients' , '/faqs' , '/_'];

  useEffect(() => {
    if (screenWidth <= 991) return; // Disable navigation for screens <= 991px

    const handleKeyPress = (event) => {
      const currentIndex = routes.indexOf(location.pathname);
      if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
        const nextIndex = (currentIndex + 1) % routes.length;
        navigate(routes[nextIndex]);
      } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        const prevIndex = (currentIndex - 1 + routes.length) % routes.length;
        navigate(routes[prevIndex]);
      }
    };

    const handleScroll = (event) => {
      const currentIndex = routes.indexOf(location.pathname);
      if (event.deltaY > 0) { // Scroll down
        const nextIndex = (currentIndex + 1) % routes.length;
        navigate(routes[nextIndex]);
      } else if (event.deltaY < 0) { // Scroll up
        const prevIndex = (currentIndex - 1 + routes.length) % routes.length;
        navigate(routes[prevIndex]);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('wheel', handleScroll);
    };
  }, [navigate, location.pathname, screenWidth]);
};

export default useArrowNavigation;
