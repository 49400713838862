import styled from 'styled-components'

export const FooterContainer = styled.footer`
    display: flex;
    z-index: 100;
    width: 100%;
    height: calc(100vh - 82px);
    background: transparent;
    padding-top: 15px;
    .container{
        height: 520px;
        overflow: hidden;
    }
    .footerLeftCol{
        padding-right: 80px;
    }
    .HeadingSecond{
        line-height: 43px;
    }
    .navAddDet{
        
        .navColF, .navColS{
            .nav-bar-list-footer{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 20px;

                
                color: var(--darkBlue);
                font-size: 15px;
                > *{
                    display: flex;
                    width: 100%;
                    cursor: pointer;
                    a{
                        display: block;
                        color: var(--darkBlue);
                        font-size: 15px;
                        
                    }
                }
            }
        }
        .colAddFooter{
            padding-left: 40px;
        }
    }
    
    
    
    .logoFooter{
        cursor:pointer;
        svg{
            width: 110px;
            height: auto;
        }
    }
    .footerText{
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .processSocial{
        display: flex;
        width: 100%;
        gap: 10px;
        svg{
            width: 40px;
            height: 40px;
        }
    }
    .copyRight{
        display: flex;
        position: absolute;
        left: 0;
        bottom: 70px;
        gap: 7px;
        font-size: 14px;
        .cRLink{
            background: var(--bgGradient);
            -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;
            font-family: var(--bold);
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .footer-img{
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
        width: 290px;
    }
    
    @media (max-width: 1250px) {
        .footerLeftCol{
            padding-right: 20px !important;
        }
    }
    @media (max-width: 1199px) {
        .navAddDet .colAddFooter{
            padding-left: calc(var(--bs-gutter-x) * .5);
        }
    }
    @media (max-width: 991px) {
        .prFooterHeading .HeadingSecond{
            font-size: 25px !important;
        }
        .footer-img{
            display: none;
        }
        .container{
            padding-top: 40px;
            height: auto;
        }
        .footerText {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .logoFooter{
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        .footerText{
            text-align: center;
        }
        .workBtn{
            display: flex;
            width: 100%;
            justify-content: center;
            margin-bottom: 30px;
        }
        .colAddFooter{
            margin-top: 40px;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            padding-left: 100px !important;
            padding-right: 100px !important;
        }
        .colAddFooter > div{
            // justify-content: center;
            width: 400px;
        }
        .nav-bar-list-footer > div{
            justify-content: center;
        }
        .copyRight{
            position: relative;
            bottom: unset;
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: center;
        }
    }
    @media (max-width: 640px) {
        .colAddFooter{
            padding-left: 50px !important;
            padding-right: 50px !important;
        }
    }
    @media (max-width: 400px) {
        .copyRight{
            margin-bottom: 80px;
        }
        .colAddFooter{
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
        }
    }
`