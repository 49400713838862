import styled from 'styled-components'
import { Search } from '../../Styled/AllImages'

export const FaqMainWrapper = styled.section`
    .rightColContentContact{
        padding-left: 30px;
    }
    .faq-wrap{
        justify-content: center;    
    }
    .faqsHeading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        .field-box-wh{
            width: auto;
            .field-box {
                margin-bottom: 0;
                width: auto;
                input{
                    width: 350px;
                    padding-left: 15px;
                    padding-right: 50px;
                }
                    
                div{
                    
                }
                button.btnSearch{
                    width: 45px;
                    height: 45px;
                    // background: url('../../../assets/images/png/search.png');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    border: none;
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    background-position: center;
                }
            }
        }
        
        
    }
    .item-wrapper{
        height: 420px;
        overflow-y: auto;
        padding-right: 15px;
        scrollbar-width: thin;
    }
    .item-container{
    background: var(--bgWhiteGradient2);
    border-radius: 18px;
    padding: 9px 13px;
    margin:12px 0;
    border: 1px solid rgba(255 ,255, 255, .5)
}
.item-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-box-heading{
        color: var(--darkBlue);
        font-size: 16px;
        font-family: var(--medium);
    }
    & > svg{
        [stroke]{
            stroke:var(--themeColor);
        }
        width: 16px;
        height: 16px;
    }
    
}

.active-item-box{
    background: var(--white);
    & .border-bottom-line{
        border-bottom:1px solid #149F72;
    }
    & > .item-box{
        color:var(--white);
        position;relative;
        
    }
    
}
`
