import React from 'react'
import { Box } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { BeepTechList } from '../../data/Data'
import { Link } from 'react-router-dom'

const BeepTechListing = () => {
    const { t } = useTranslation();
    
    return (
        <Box className='portfolioTechListWrapper'>
            {BeepTechList.map((item, index) => (
                <Link className="techList" key={index}>
                    {item.beepTechIcon}
                </Link>
            ))}
        </Box>
    )
}

export default BeepTechListing
