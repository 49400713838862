import React from 'react'
import { Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, SpanStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading } from '../../Styled/CustomElements'
import { Cross, GetQuoteEle1, GetQuoteEle2, GetQuoteEle3, GetQuoteEle4, GetQuoteMain } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import "react-toastify/dist/ReactToastify.css";
import { GetQuoteSection } from './Styled'
import ContactForm from './GetQuoteForm'


const CustomModal = ({ show, onHide }) => {
    
    const { t } = useTranslation();
    

    return (
        <>
        <Modal 
            show={show} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal-Quote"
            // id={0}
        >
            
            <Modal.Body>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                <GetQuoteSection>
                    <Container className='containerPr container-fluid'>
                        <Row className='leftRightColWrap getQuote-wrap'>
                            <Box className='leftColIco leftColIcoGetQuote'>
                                <Box className='contatcHeading'>
                                    <PrimaryHeading HlabelF={t("Get_a")} HlabelS={t("Quote")} />
                                </Box>
                                <SpanStyled size='15px' className='mb-4 contactTxt'>
                                    {t('getQuoteText')}
                                </SpanStyled>
                                <Box className='quoteEleWrapper'>
                                    <img src={GetQuoteMain} alt='Get Quote Image' className="scaleAnim getQuoteMain" />
                                    <img src={GetQuoteEle1} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle1' />
                                    <img src={GetQuoteEle2} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle2' />
                                    <img src={GetQuoteEle3} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle3' />
                                    <img src={GetQuoteEle4} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle4' />

                                    <Box className='modalElement modalElement3'></Box>
                                    <Box className='modalElement modalElement4'></Box>
                                </Box>
                                
                            </Box>
                            <Box className='rightColContent rightColContentQuote'>
                                <ContactForm />

                            </Box>
                            
                        </Row>

                    </Container>
                </GetQuoteSection>
            </Modal.Body>
            
        </Modal>
        
        </>
    );
};

export default CustomModal;
const TabsWrapper = styled.div`
    

`