import React, { useState } from 'react'
import Slider from 'react-slick'
import { Box } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { MobileDevItems } from '../../data/Data'
import { SliderArrowLeft, SliderArrowRight } from '../../Styled/AllImages'

const MobileDevelopments = () => {
    const { t } = useTranslation();
    
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 1500,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft />,
        nextArrow: <SliderArrowRight />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <Box className='devsServicesWrapper'>
            <Box className='sliderContainer sliderContainerExpertise'>
        
                <Slider {...settings}>
                    {MobileDevItems.map((item, index) => (
                        <Box key={index} className='expertiseCol'>
                            <Box className="expertiseImg">
                                {item.img}
                            </Box>
                            <Box className="expertiseTxt">{t(item.devTxt)}</Box>
                        </Box>
                    ))}
                </Slider>
                
            </Box>
        </Box>
        
    )
}

export default MobileDevelopments
