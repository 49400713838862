import React from 'react'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { PrimaryHeading } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'


const TabFirst = () => {
    const { t } = useTranslation();
    
    return (
        <TabsWrapper className='TabsAboutWrapper'>
            
            <Box className='tabInnerHeading'>
                <PrimaryHeading HlabelF={t("Fowl")} HlabelS={t("in_a_Nutshell")} size='25px' />
            </Box>
            
            <SpanStyled size='15px' className='tabTxt'>
                {t('tab1_Text1')}
                <br></br><br></br>
                {t('tab1_Text2')}
            </SpanStyled>
        </TabsWrapper>
    )
}

export default TabFirst
