import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Box, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { ButtonWithIcon } from '../../Styled/CustomElements'


const ErrorPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const imgurl = 'https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif';
    return (
        <ErrorPageWrapper>
            {/* <div class="col-md-6 align-self-center error-page">
                <Box className='four_zero_four_bg' style={{ backgroundImage: `url(${imgurl})` }}>

                </Box>
                <MainHeading>404</MainHeading>
                <SubHeading>UH OH! You're lost.</SubHeading>
                <TextStyled>The page you are looking for does not exist.
                    How you got here is a mystery. But you can click the button below
                    to go back to the homepage.
                </TextStyled>
                <button className='btn btn-outline-warning' onClick={() => navigate("/")} >Home</button>
            </div> */}
            <div class="mars"></div>
            <img src="https://assets.codepen.io/1538474/404.svg" class="logo-404" />
            <img src="https://assets.codepen.io/1538474/meteor.svg" class="meteor" />
            <Box className='wrapper404'>
                <SpanStyled size="34px" className="title404">{t("OhNo")}</SpanStyled>
                <TextStyled class="subtitle">
                  {t("OhNoT1")} <br /> {t("OhNoT2")}
                </TextStyled>
                <ButtonWithIcon customClass="homeButton" label={t("Home")}  onClick={() => navigate('/home')} />
            </Box>
            <img src="https://assets.codepen.io/1538474/astronaut.svg" class="astronaut" />
            <img src="https://assets.codepen.io/1538474/spaceship.svg" class="spaceship" />
        </ErrorPageWrapper>
    )
}

export default ErrorPage

const ErrorPageWrapper = styled.div`
    width: 100%;
    height: 100vh;
    text-align: center;
    background: var(--darkColor);
    display: flex;
    align-items: center;
    justify-content: center;


$nunito-font: 'Nunito', sans-serif;

// mixins
@mixin breakpoint($point) {
    @if $point==mobile {
        @media (max-width: 480px) and (min-width: 320px) {
            @content ;
        }
    }
}

// keyrames
@keyframes floating {
    from { transform: translateY(0px); }
    65%  { transform: translateY(15px); }
    to   { transform: translateY(-0px); }
}

html {
  height: 100%;
}

body{
  background-image: url('https://assets.codepen.io/1538474/star.svg'),linear-gradient(to bottom, #05007A, #4D007D);
  height: 100%;
  margin: 0;
  background-attachment: fixed;
  overflow: hidden;
}

.mars{
  left:0;
  right:0;
  bottom:0;
  position:absolute;
  height: 27vmin;
  background: url('https://assets.codepen.io/1538474/mars.svg') no-repeat bottom center;
  background-size: cover;
}

.logo-404{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 16vmin;
  width: 30vmin;

  @include breakpoint(mobile){
    top: 45vmin;
  }
}

.meteor{
  position: absolute;
  right: 2vmin;
  top: 16vmin;
}
.wrapper404{
  position: relative;
  z-index: 500
}
.title404{
  
  // font-family: var(--medium);
  // font-weight: 600;
  text-align: center;
  font-size: 6vmin;
  margin-top: 5vmin;

  @include breakpoint(mobile){
    margin-top: 65vmin;
  }
}

.subtitle{
  color: white;
  font-family: $nunito-font;
  font-weight: 400;
  text-align: center;
  font-size: 3.5vmin;
  margin-top: -1vmin;
  margin-bottom: 9vmin;
}

.btn-back{
  border: 1px solid white;
  color: white;
  height: 5vmin;
  padding: 12px;
  font-family: $nunito-font;
  text-decoration: none;
  border-radius: 5px;

  &:hover{
    background: white;
    color: #4D007D;
  }

  @include breakpoint(mobile){
    font-size: 3.5vmin;
  }
}
  .homeButton{
  justify-content: center;
  font-family: ${props => props.family ? props.family : "var(--regular)"},
    ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
    }

.astronaut{
  position: absolute;
  top: 18vmin;
  left: 10vmin;
  height: 30vmin;
  animation: floating 3s infinite ease-in-out;

  @include breakpoint(mobile){
    top: 2vmin;
  }
}

.spaceship{
  position: absolute;
  bottom: 15vmin;
  right: 24vmin;

  @include breakpoint(mobile){
    width: 45vmin;
    bottom: 18vmin;
  }
}


`