import React from 'react'
import { Box } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { SocialMediaItems } from '../../data/Data'

const SocialMedia = () => {
    const { t } = useTranslation();
    
    return (
        <Box className='devsServicesWrapper'>
            {SocialMediaItems.map((item, index) => (
                <Box className='devServicesCol' key={index}>
                    <Box className='devServicesImg'>
                        {item.img}
                    </Box>
                    <Box className='devServicesTitle'>{t(item.devTxt)}</Box>
                    
                </Box>
            ))}
        </Box>
    )
}

export default SocialMedia
