import React from 'react'
import { Col } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'

const PrMoreDetail = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Quick')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('QuickTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Anywhere')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('AnywhereTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Bookings')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('BookingsTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-2'>
                <Box className="smallTitleHeading">
                    {t('Communication')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('CommunicationTxt')}
                </SpanStyled>
            </Col>
        </>
    )
}

export default PrMoreDetail
