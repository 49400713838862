import React from 'react'
import { Box } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { MaintenanceSupportItems } from '../../data/Data'

const MaintenanceSupport = () => {
    const { t } = useTranslation();
    
    return (
        <Box className='devsServicesWrapper'>
            {MaintenanceSupportItems.map((item, index) => (
                <Box className='devServicesCol' key={index}>
                    {/* <Box className='devServicesImg'>
                        {item.img}
                    </Box> */}
                    {/* <Box className='devServicesTitle'>{t(item.devTxt)}</Box> */}
                    
                </Box>
            ))}
        </Box>
    )
}

export default MaintenanceSupport
