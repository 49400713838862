import styled from 'styled-components'

export const TabsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .tabInnerHeading{
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }
`