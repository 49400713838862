import styled from 'styled-components'

export const GetQuoteSection = styled.section`
    
    .leftColIcoGetQuote{
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-top: 0px;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .optionListWrapper{
        margin: 0 0 30px 0;
        padding: 0 0 3px 0;
        position: relative;
        gap: 20px;
        align-items: flex-start;
    }
    .optionListWrapper > *{
        flex: 0 0 calc(33% - 20px);
        width: calc(33% - 20px);
        
    }
    
    
`


export const ContactFormWrapper = styled.div`
    width:100%;
    

`